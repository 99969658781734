// deno-lint-ignore-file ban-types

import Promiseable from 'api/types/Promiseable.ts';

export default function Arrayify<T>(item: T | Iterable<T> | T[]): (T & {} & (T extends null ? null : {}))[];
export default function Arrayify(item?: undefined): undefined;
export default function Arrayify<T>(item?: T | Iterable<T> | T[]) {
    switch (typeof item) {
        case 'undefined':
            return undefined;

        case 'object': {
            switch (true) {
                case !item:
                    return [item];

                case Array.isArray(item):
                    return item;

                case Symbol.iterator in item!:
                    return Array.from(item as unknown as Iterable<T>);

                default:
                    return [item];
            }
        }

        default:
            return [item];
    }
}

export async function asyncArrayify<T>(
    item: Promiseable<T> | Promiseable<T[]> | Iterable<T> | AsyncIterable<T>,
): Promise<(T & {} & (T extends null ? null : {}))[]>;

export async function asyncArrayify(
    item?: Promiseable<undefined>,
): Promise<undefined>;

export async function asyncArrayify<T>(
    item?: Promiseable<undefined> | Promiseable<T> | Promiseable<T[]> | Iterable<T> | AsyncIterable<T>,
): Promise<(T[]) | undefined> {
    switch (typeof item) {
        case 'object': {
            switch (true) {
                case !item:
                    return [item];

                case Symbol.iterator in item!:
                    return Array.from(item);

                case Symbol.asyncIterator in item!: {
                    return Array.fromAsync(item as AsyncIterable<T>);
                }

                default: {
                    if (item instanceof Promise) {
                        return await asyncArrayify(await item);
                    }

                    if (typeof item == 'undefined') {
                        return undefined;
                    }

                    return Array.isArray(item) ? item : [item];
                }
            }
        }

        case 'undefined':
            return undefined;

        default:
            return [item];
    }
}

export function Dearrayify<T>(item: T | T[] | Iterable<T>): T;
export function Dearrayify(item?: undefined): undefined;
export function Dearrayify<T>(item?: T | T[] | Iterable<T> | undefined): T | undefined {
    return Arrayify(item)?.[0];
}

export async function asyncDearrayify<T>(
    item: Promiseable<T> | Promiseable<T[]> | Iterable<T> | AsyncIterable<T>,
): Promise<(T & {} & (T extends null ? null : {}))[]>;
export async function asyncDearrayify(item?: Promiseable<undefined>): Promise<undefined>;
export async function asyncDearrayify<T>(
    item?: Promiseable<undefined> | Promiseable<T> | Promiseable<T[]> | Iterable<T> | AsyncIterable<T>,
) {
    return (await asyncArrayify(item))?.[0];
}
